import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.min.css'

/**
 * For each object, the key is the company turnover in millions and the value is the price in pounds
 * Up to £50m it's pretty self-explanatory. From there, see the comment on `const range` further down
 *
 * From £50m the next key (eg. 76) minus one is the upper bound for the range (ie. 51 runs up to £75m)
 * Because of this, when updating prices it's important to check the price is correct, but also the next key
 * to ensure the turnover range for that price is also correct
 *
 * There seem to be two redundant keys of 175 and 1000. Deleting them seems riskier than keeping them
 */
const prices = {
  monthly: {
    0: 47,
    2: 47,
    3: 48,
    4: 49,
    5: 50,
    6: 51,
    7: 53,
    8: 54,
    9: 56,
    10: 61,
    15: 66,
    20: 74,
    25: 84,
    30: 97,
    35: 107,
    40: 117,
    45: 128,
    50: 138,
    51: 163,
    76: 189,
    101: 281,
    126: 306,
    151: 332,
    175: 332,
    176: 357,
    201: 383,
    226: 408,
    251: 459,
    301: 510,
    351: 561,
    401: 612,
    451: 663,
    501: 816,
    601: 1020,
    701: 1224,
    801: 1428,
    901: 1632,
    1000: 1632,
    1100: 1938,
  },
  quarterly: {
    0: 141,
    2: 141,
    3: 144,
    4: 147,
    5: 150,
    6: 153,
    7: 159,
    8: 162,
    9: 168,
    10: 183,
    15: 198,
    20: 222,
    25: 252,
    30: 291,
    35: 321,
    40: 351,
    45: 384,
    50: 414,
    51: 489,
    76: 567,
    101: 843,
    126: 918,
    151: 996,
    176: 1071,
    201: 1149,
    226: 1224,
    251: 1377,
    301: 1530,
    351: 1683,
    401: 1836,
    451: 1989,
    501: 2448,
    601: 3060,
    701: 3672,
    801: 4284,
    901: 4896,
    1000: 4896,
    1100: 5814,
  },
  annually: {
    0: 469,
    2: 469,
    3: 479,
    4: 490,
    5: 500,
    6: 510,
    7: 525,
    8: 541,
    9: 556,
    10: 612,
    15: 663,
    20: 740,
    25: 842,
    30: 969,
    35: 1070,
    40: 1173,
    45: 1275,
    50: 1377,
    51: 1632,
    76: 1887,
    101: 2805,
    126: 3060,
    151: 3315,
    176: 3570,
    201: 3825,
    226: 4080,
    251: 4590,
    301: 5100,
    351: 5610,
    401: 6120,
    451: 6630,
    501: 8160,
    601: 10200,
    701: 12240,
    801: 14280,
    901: 16320,
    1000: 16320,
    1100: 19380,
  },
}

const formatPrice = (price) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(price)
}

/**
 * The object key here is the visual percentage of the slider to allocate to a range and doesn't affect calculations
 * For the values, the first number is the value of the slider, second is the step
 * eg. '50%': [50, 25] means that the 50% mark of the slider represents the 50m price, and will step up in 25m
 *    increments, so 75m - 100m - 125m ... 225m - 250m, then it falls into the 70% band
 */
const range = {
  min: [2, 1],
  '30%': [10, 5],
  '50%': [50, 25],
  '70%': [250, 50],
  '90%': [500, 100],
  max: 1100,
}

const PriceRange = () => {
  const [activePlan, setActivePlan] = useState('annually')
  const [turnover, setTurnover] = useState('0')
  const [priceTurnoverLabel, setPriceTurnoverLabel] = useState(
    'Less than £2 million'
  )
  const [pricePerMonthLabel, setPricePerMonthLabel] = useState('£0')
  const [timeFrameLabel, setTimeFrameLabel] = useState('annum')

  useEffect(() => {
    let price
    // TODO: Rework confusing slider values from old site
    if (turnover == 2) {
      setPriceTurnoverLabel('Less than £2 million')
      price = prices[activePlan][0]
    } else if (turnover > 1000) {
      setPriceTurnoverLabel('Greater than £1 billion')
      price = prices[activePlan][turnover]
    } else if (turnover >= 1000) {
      setPriceTurnoverLabel('Less than £1 billion')
      price = prices[activePlan][turnover]
    } else {
      let match = '0'

      for (let key in prices[activePlan]) {
        if (turnover >= parseInt(key)) {
          match = key
        }
        if (turnover < parseInt(key)) break
      }

      setPriceTurnoverLabel(`Less than £${turnover} million`)
      price = prices[activePlan][match]
    }

    setPricePerMonthLabel(formatPrice(price))

    switch (activePlan) {
      case 'monthly':
        setTimeFrameLabel('month')
        break
      case 'quarterly':
        setTimeFrameLabel('quarter')
        break
      case 'annually':
        setTimeFrameLabel('annum')
        break
    }
  }, [activePlan, turnover]) // Array ensures that effect is only run on state change
  return (
    <section
      id="pricing"
      className="c-section c-section--forPricing c-section--forPricingRange"
    >
      <div className="c-section__outer container">
        <div className="c-section__tag">Our pricing</div>
        <div className="c-section__inner">
          <div className="row">
            <div className="col-12 col-lg-4  col-xl-5 text-left">
              <div className="c-pricingRangeIntro pr-xl-5">
                <h2 className="pr-xl-5">Payment options to suit you</h2>
                <p className="mt-3 mb-0 pr-xl-5">
                  The cost of your Erudus subscription is calculated by the
                  annual turnover of your business. Pay monthly, quarterly or
                  annually by direct debit, card or bacs.
                </p>
                <p className="mt-3 mb-0 pr-xl-5">
                  Your subscription allows you to upload data and images for an
                  unlimited number of products to the Erudus platform.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-8 col-xl-7">
              <ul className="c-pricePlanToggle mb-4">
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'monthly' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('monthly')}
                >
                  Monthly
                </li>
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'quarterly' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('quarterly')}
                >
                  Quarterly
                </li>
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'annually' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('annually')}
                >
                  Annually
                </li>
              </ul>
              <div className="c-priceRange">
                <div className="c-priceRangeHeader">
                  <div className="c-priceRangeTurnover">
                    <h5 className="mb-0">Annual turnover:</h5>
                    <h5 className="mb-0">{priceTurnoverLabel}</h5>
                  </div>
                  <p className="c-priceRangeLabel mb-0 text-right">
                    <span className="d-block">{pricePerMonthLabel}</span>
                    {`Per ${timeFrameLabel}`}
                  </p>
                </div>
                <div className="c-priceRangeSliderWrapper">
                  <Nouislider
                    range={range}
                    start={1}
                    className="c-priceRangeSlider"
                    onUpdate={(value) => {
                      setTurnover(parseInt(value))
                    }}
                  />
                </div>
                <div className="c-priceRangeFooter">
                  <p className="c-priceRangeLabel">
                    <span>{pricePerMonthLabel}</span> Per {timeFrameLabel}
                  </p>
                  <Link
                    to="/manufacturers/sign-up"
                    state={{ activePlan }}
                    className="c-button c-button--primary"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
              <p className="c-priceRangeLinks">
                This is subscription pricing for <strong>Manufacturers</strong>.
                See <Link to="/wholesalers#pricing">Wholesaler</Link>,{' '}
                <Link to="/caterers#pricing">Caterer</Link> or{' '}
                <Link to="/retailers#pricing">Retailer</Link> pricing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PriceRange
